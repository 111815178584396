import { SVGProps } from 'react'

export const Laptop = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width='24'
    height='24'
    viewBox='0 0 25 25'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    {...props}
  >
    <g>
      <path
        d='M6 9C6 6.17157 6 4.75736 6.87868 3.87868C7.75736 3 9.17157 3 12 3H18C20.8284 3 22.2426 3 23.1213 3.87868C24 4.75736 24 6.17157 24 9V14C24 15.8856 24 16.8284 23.4142 17.4142C22.8284 18 21.8856 18 20 18H10C8.11438 18 7.17157 18 6.58579 17.4142C6 16.8284 6 15.8856 6 14V9Z'
        stroke='#FFF'
        strokeWidth='1.5'
      />
      <path d='M25 21H5' stroke='#FFF' strokeWidth='1.5' strokeLinecap='round' />
      <path d='M18 15H12' stroke='#FFF' strokeWidth='1.5' strokeLinecap='round' />
    </g>
    <defs>
      <filter
        id='filter0_d_2255_13947'
        x='-1'
        y='0'
        width='24'
        height='24'
        filterUnits='userSpaceOnUse'
        colorInterpolationFilters='sRGB'
      >
        <feFlood floodOpacity='0' result='BackgroundImageFix' />
        <feColorMatrix
          in='SourceAlpha'
          type='matrix'
          values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'
          result='hardAlpha'
        />
        <feOffset dy='4' />
        <feGaussianBlur stdDeviation='2' />
        <feComposite in2='hardAlpha' operator='out' />
        <feColorMatrix type='matrix' values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0' />
        <feBlend mode='normal' in2='BackgroundImageFix' result='effect1_dropShadow_2255_13947' />
        <feBlend
          mode='normal'
          in='SourceGraphic'
          in2='effect1_dropShadow_2255_13947'
          result='shape'
        />
      </filter>
    </defs>
  </svg>
)

export const Mobile = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width='24'
    height='24'
    viewBox='0 0 24 24'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    {...props}
  >
    <g opacity='1'>
      <path
        d='M4 10C4 6.22876 4 4.34315 5.17157 3.17157C6.34315 2 8.22876 2 12 2C15.7712 2 17.6569 2 18.8284 3.17157C20 4.34315 20 6.22876 20 10V14C20 17.7712 20 19.6569 18.8284 20.8284C17.6569 22 15.7712 22 12 22C8.22876 22 6.34315 22 5.17157 20.8284C4 19.6569 4 17.7712 4 14V10Z'
        stroke='#FFF'
        strokeWidth='1.5'
      />
      <path d='M15 19H9' stroke='#FFF' strokeWidth='1.5' strokeLinecap='round' />
    </g>
  </svg>
)

export const Info = (props: SVGProps<SVGSVGElement>) => (
  <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <g opacity='0.3'>
      <path
        d='M12 8V8.5M12 12V16M12 21C16.9706 21 21 16.9706 21 12C21 7.02944 16.9706 3 12 3C7.02944 3 3 7.02944 3 12C3 16.9706 7.02944 21 12 21Z'
        stroke='#FAFAFA'
        strokeWidth='2'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </g>
  </svg>
)

export const PlaceholderStars = (props: SVGProps<SVGSVGElement>) => (
  <svg width='34' height='34' viewBox='0 0 34 34' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M13 1C13 7.62742 7.62742 13 1 13C7.62742 13 13 18.3726 13 25C13 18.3726 18.3726 13 25 13C18.3726 13 13 7.62742 13 1Z'
      stroke='#FAFAFA'
      strokeWidth='2'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M13 1C13 7.62742 7.62742 13 1 13C7.62742 13 13 18.3726 13 25C13 18.3726 18.3726 13 25 13C18.3726 13 13 7.62742 13 1Z'
      stroke='url(#paint0_radial_4919_992)'
      strokeWidth='2'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M28 23C28 25.7614 25.7614 28 23 28C25.7614 28 28 30.2386 28 33C28 30.2386 30.2386 28 33 28C30.2386 28 28 25.7614 28 23Z'
      stroke='#FAFAFA'
      strokeWidth='2'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M28 23C28 25.7614 25.7614 28 23 28C25.7614 28 28 30.2386 28 33C28 30.2386 30.2386 28 33 28C30.2386 28 28 25.7614 28 23Z'
      stroke='url(#paint1_radial_4919_992)'
      strokeWidth='2'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <defs>
      <radialGradient
        id='paint0_radial_4919_992'
        cx='0'
        cy='0'
        r='1'
        gradientUnits='userSpaceOnUse'
        gradientTransform='translate(2.82982 2.5) rotate(45) scale(35.596 34.1833)'
      >
        <stop offset='0.0132422' stopColor='#C5F04D' />
        <stop offset='0.523889' stopColor='#FFCC00' />
        <stop offset='1' stopColor='#FF60B7' />
      </radialGradient>
      <radialGradient
        id='paint1_radial_4919_992'
        cx='0'
        cy='0'
        r='1'
        gradientUnits='userSpaceOnUse'
        gradientTransform='translate(2.82982 2.5) rotate(45) scale(35.596 34.1833)'
      >
        <stop offset='0.0132422' stopColor='#C5F04D' />
        <stop offset='0.523889' stopColor='#FFCC00' />
        <stop offset='1' stopColor='#FF60B7' />
      </radialGradient>
    </defs>
  </svg>
)

export const RemoveTag = (props: SVGProps<SVGSVGElement>) => (
  <svg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M10.5 5.50003L5.5 10.5M10.5 10.5L5.5 5.50003M8 15.5C12.1421 15.5 15.5 12.1421 15.5 8C15.5 3.85786 12.1421 0.5 8 0.5C3.85786 0.5 0.5 3.85786 0.5 8C0.5 12.1421 3.85786 15.5 8 15.5Z'
      stroke='#FAFAFA'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </svg>
)

export const SubjectArrow = (props: SVGProps<SVGSVGElement>) => (
  <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path d='M15.2941 5H3L9.70588 12L3 19H15.2941L22 12L15.2941 5Z' fill='#FF60B7' />
  </svg>
)

export const WimbleLogo = ({
  isHovered,
  ...props
}: SVGProps<SVGSVGElement> & { isHovered?: boolean }) => (
  <svg width='78' height='20' viewBox='0 0 78 20' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <g opacity={isHovered ? '1' : '0.7'}>
      <path
        d='M14.1641 0.90898C10.398 -0.835512 5.73794 -0.0251271 2.81696 2.96278C2.2774 3.50784 1.84492 4.12744 1.42689 4.76913C2.92718 3.8474 4.6908 3.32158 6.57076 3.32158C8.10167 3.32158 9.62644 3.68656 10.9981 4.37322C11.6656 4.70727 12.3331 5.11556 12.8965 5.61663C13.4537 6.11153 13.8946 6.72395 14.6417 6.95903C15.8971 7.36113 17.3361 6.49507 17.6974 5.23928C18.3494 3.15344 15.6855 1.61062 14.1641 0.90898Z'
        fill={isHovered ? '#FFBA00' : '#FAFAFA'}
      />
      <path
        d='M0.0596498 10.7888C0.445439 14.9583 3.47053 18.6267 7.49376 19.6845C8.23545 19.875 8.97828 19.956 9.74113 20C8.20409 19.1463 6.86913 17.872 5.92609 16.2265C5.16064 14.8902 4.71362 13.3685 4.61564 11.8281C4.56665 11.0796 4.58502 10.2878 4.73199 9.54542C4.87896 8.80927 5.17901 8.11642 5.01367 7.34934C4.72586 6.05025 3.26843 5.22749 2.00696 5.5368C-0.108718 6.03002 -0.0929954 9.0993 0.0596498 10.7888Z'
        fill={isHovered ? '#FFBA00' : '#FAFAFA'}
      />
      <path
        d='M15.5891 18.1992C18.9694 15.7804 20.6044 11.2955 19.5022 7.24353C19.303 6.50147 18.9883 5.81487 18.651 5.12787C18.6877 6.89711 18.2591 8.70346 17.3221 10.3552C16.5567 11.6914 15.4789 12.8482 14.2052 13.7019C13.5867 14.1163 12.9009 14.4999 12.1905 14.7411C11.4863 14.9824 10.7392 15.0628 10.1636 15.5948C9.19606 16.4918 9.21444 18.1868 10.1085 19.1333C11.6423 20.6828 14.1801 19.2031 15.5891 18.1992Z'
        fill={isHovered ? '#FFBA00' : '#FAFAFA'}
      />
      <path
        d='M76.1256 12.8426L78 13.588C77.4325 15.1826 76.1084 16.1532 74.1996 16.1532C71.6718 16.1532 69.8833 14.4199 69.8833 11.82C69.8833 9.48006 71.5342 7.59078 73.9417 7.59078C76.1944 7.59078 77.7249 9.02941 77.7249 11.2827C77.7249 11.6467 77.6905 11.976 77.6217 12.288H72.0845C72.1017 13.536 73.0647 14.2466 74.234 14.2466C75.2314 14.2466 75.8677 13.7786 76.1256 12.8426ZM72.0845 10.8147H75.5753L75.5925 10.7454C75.5925 9.91339 74.9219 9.37607 73.9417 9.37607C73.0475 9.37607 72.2908 9.91339 72.0845 10.8147Z'
        fill={isHovered ? '#191A1A' : '#FAFAFA'}
      />
      <path
        d='M66.3064 15.9798V3.84677H68.5076V15.9798H66.3064Z'
        fill={isHovered ? '#191A1A' : '#FAFAFA'}
      />
      <path
        d='M56.2872 3.84677H58.4883V8.99464C59.0386 8.14533 60.0188 7.59068 61.1022 7.59068C63.3033 7.59068 64.9369 9.32397 64.9369 11.9066C64.9369 14.4892 63.2689 16.1531 60.9818 16.1531C59.8984 16.1531 58.9526 15.7198 58.4023 14.8878V15.9798H56.2872V3.84677ZM60.5691 14.2638C61.79 14.2638 62.7358 13.3452 62.7358 11.9066C62.7358 10.4333 61.79 9.4973 60.5691 9.4973C59.3825 9.4973 58.4023 10.4333 58.4023 11.9066C58.4023 13.3625 59.3825 14.2638 60.5691 14.2638Z'
        fill={isHovered ? '#191A1A' : '#FAFAFA'}
      />
      <path
        d='M43.0431 7.7641H45.2442V8.76941C45.6569 7.98943 46.3276 7.59078 47.2562 7.59078C48.2364 7.59078 49.0274 8.11076 49.3713 9.13341C49.8528 8.09343 50.6954 7.59078 51.7272 7.59078C53.3953 7.59078 54.4442 8.63075 54.4442 10.4334V15.9799H52.2431V10.8494C52.2431 9.94805 51.7444 9.42806 51.0394 9.42806C50.2999 9.42806 49.8356 9.98272 49.8356 10.936V15.9799H47.6517V10.8494C47.6517 9.96539 47.153 9.48006 46.448 9.48006C45.7429 9.48006 45.2442 10.0347 45.2442 11.0227V15.9799H43.0431V7.7641Z'
        fill={isHovered ? '#191A1A' : '#FAFAFA'}
      />
      <path
        d='M39.0021 7.76404H41.2032V15.9798H39.0021V7.76404ZM40.0908 6.64674C39.3602 6.64673 38.7679 6.04908 38.7679 5.31267C38.7679 4.57625 39.3602 3.9786 40.0908 3.97861C40.8214 3.97862 41.4137 4.57627 41.4137 5.31267C41.4137 6.0491 40.8214 6.64675 40.0908 6.64674Z'
        fill={isHovered ? '#191A1A' : '#FAFAFA'}
      />
      <path
        d='M27.3364 7.76396L28.8325 13.8998L30.2598 7.76396H32.8392L34.2493 13.8998L35.7626 7.76396H37.9465L35.711 15.9798H32.8736L31.5495 10.6412L30.2082 15.9798H27.3708L25.1353 7.76396H27.3364Z'
        fill={isHovered ? '#191A1A' : '#FAFAFA'}
      />
    </g>
  </svg>
)

export const DinomailLogo = ({
  isHovered,
  ...props
}: SVGProps<SVGSVGElement> & { isHovered?: boolean }) => (
  <svg width='77' height='22' viewBox='0 0 77 22' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <g opacity={isHovered ? '1' : '0.7'}>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M17.0604 6.26211C16.7025 6.8843 16.0702 7.55775 15.2901 7.37746C14.9079 7.28908 14.6977 6.93733 14.7185 6.54846C14.7568 5.82728 15.3944 5.24044 15.8843 4.77733C17.4769 3.27315 19.4578 2.11311 21.1327 0.706582C21.4912 0.405497 21.4369 -0.0150079 20.9068 0.0861591C20.0104 0.25724 13.3772 2.28996 10.8287 3.03626C10.2339 3.21045 10.2137 3.39508 10.7801 3.58068C11.3134 3.7539 11.9719 3.8794 12.361 4.32483C12.8457 4.87985 12.5278 5.84142 11.8694 6.09065C11.0268 6.41059 10.5733 5.47376 10.351 4.81092C10.1216 4.12686 9.79156 3.32084 8.91769 3.563C7.22556 4.03318 2.24124 5.36241 1.45424 5.59749C1.24055 5.66113 0.0557137 5.88208 0.000120034 6.16489C-0.0189903 6.26388 2.24993 6.53608 2.4115 6.55553C3.74922 6.72168 5.18076 6.76764 6.44204 7.32796C7.26726 7.69385 7.95349 8.39382 8.25926 9.2617C8.53375 10.043 8.38955 10.8631 8.02646 11.5878C7.84752 11.9449 7.62167 12.2772 7.37844 12.5918C7.1856 12.8393 6.9806 13.2618 6.68005 12.9347C6.56539 12.811 6.43856 12.6767 6.28394 12.8287C6.08763 13.0214 6.30653 13.2865 6.57407 13.5658C6.83988 13.8451 7.29332 13.7602 7.507 13.5499C7.5626 13.4951 8.62756 12.1623 8.78218 12.2436C8.86905 12.2896 8.67447 12.91 8.6571 13.0214C8.58761 13.4686 8.70574 13.5357 9.08447 13.3165C9.11053 13.3024 9.96876 12.6502 10.1303 12.7845C10.3093 12.933 9.48753 14.0695 9.08969 14.4602C8.80303 14.7412 8.59803 14.5768 8.41561 14.4284C8.2662 14.3082 8.1307 14.2003 7.97781 14.3506C7.82493 14.5008 7.70158 14.7889 8.2332 15.3015C8.99935 16.0404 9.66994 15.0912 10.4135 14.0395C11.0928 13.0779 12.1074 11.5224 13.4468 12.3674C14.2599 12.8817 14.4996 13.9971 14.6595 14.8862C14.9844 16.6873 15.1372 18.5062 15.2102 20.3339C15.2519 21.3838 15.2779 21.8834 15.4204 21.9265C16.2109 22.1654 20.8476 4.49943 19.8052 3.4389C18.7629 2.37836 17.4016 5.68228 17.0604 6.26388V6.26211ZM15.4899 2.55901C15.7783 2.22847 16.5583 2.59613 16.2109 3.16176C15.8652 3.65668 14.9965 3.12641 15.4899 2.55901Z'
        fill={isHovered ? '#7E9D00' : '#FAFAFA'}
      />
      <path
        d='M29.1384 10.1706C29.6788 9.58296 30.3582 9.28915 31.1766 9.28915C31.8483 9.28915 32.4235 9.52111 32.9022 9.98502V7.13195H34.8014V15.3316H32.9369V14.7285C32.4428 15.2234 31.8367 15.4708 31.1187 15.4708C30.3235 15.4708 29.6557 15.177 29.1152 14.5894C28.5825 13.994 28.3161 13.2556 28.3161 12.3742C28.3161 11.4927 28.5902 10.7582 29.1384 10.1706ZM30.5976 13.3832C30.8523 13.6461 31.1727 13.7775 31.5588 13.7775C31.9525 13.7775 32.2807 13.6461 32.5432 13.3832C32.8057 13.1203 32.9369 12.7878 32.9369 12.3858C32.9369 11.9837 32.8057 11.6512 32.5432 11.3884C32.2807 11.1177 31.9564 10.9824 31.5704 10.9824C31.1843 10.9824 30.8601 11.1177 30.5976 11.3884C30.3428 11.6512 30.2154 11.9837 30.2154 12.3858C30.2154 12.7878 30.3428 13.1203 30.5976 13.3832Z'
        fill={isHovered ? '#2A2B3B' : '#FAFAFA'}
      />
      <path
        d='M36.0699 15.3316V9.40513H38.0386V15.3316H36.0699ZM35.9078 7.67705C35.9078 7.35231 36.0159 7.0817 36.232 6.8652C36.4559 6.64098 36.73 6.52887 37.0543 6.52887C37.3785 6.52887 37.6488 6.64098 37.8649 6.8652C38.0888 7.0817 38.2008 7.35231 38.2008 7.67705C38.2008 7.99406 38.0927 8.26081 37.8765 8.4773C37.6526 8.70153 37.3785 8.81364 37.0543 8.81364C36.73 8.81364 36.4559 8.70539 36.232 8.4889C36.0159 8.26468 35.9078 7.99406 35.9078 7.67705Z'
        fill={isHovered ? '#2A2B3B' : '#FAFAFA'}
      />
      <path
        d='M41.1802 9.40513V9.98502C41.628 9.51338 42.1878 9.27755 42.8595 9.27755C43.5466 9.27755 44.1025 9.49018 44.5271 9.91544C44.9517 10.3407 45.1641 10.8974 45.1641 11.5855V15.3316H43.2185V11.9914C43.2185 11.6899 43.1335 11.4464 42.9637 11.2608C42.7938 11.0675 42.5738 10.9708 42.3036 10.9708C42.0102 10.9708 41.7631 11.083 41.5624 11.3072C41.3694 11.5314 41.2729 11.8213 41.2729 12.177V15.3316H39.3157V9.40513H41.1802Z'
        fill={isHovered ? '#2A2B3B' : '#FAFAFA'}
      />
      <path
        d='M46.9979 10.1474C47.6001 9.55977 48.376 9.26596 49.3257 9.26596C50.2753 9.26596 51.0512 9.56363 51.6534 10.159C52.2556 10.7466 52.5567 11.4889 52.5567 12.3858C52.5567 13.2904 52.2518 14.0327 51.6418 14.6126C51.0319 15.1847 50.2599 15.4708 49.3257 15.4708C48.3838 15.4708 47.6078 15.1809 46.9979 14.601C46.388 14.0133 46.083 13.2749 46.083 12.3858C46.083 11.4889 46.388 10.7427 46.9979 10.1474ZM48.3413 13.3716C48.6038 13.6422 48.9319 13.7775 49.3257 13.7775C49.7271 13.7775 50.0591 13.6461 50.3216 13.3832C50.5841 13.1126 50.7154 12.7724 50.7154 12.3626C50.7154 11.9683 50.5841 11.6358 50.3216 11.3652C50.0591 11.0945 49.731 10.9592 49.3372 10.9592C48.9435 10.9592 48.6115 11.0945 48.3413 11.3652C48.0711 11.6358 47.936 11.9683 47.936 12.3626C47.936 12.7724 48.0711 13.1087 48.3413 13.3716Z'
        fill={isHovered ? '#2A2B3B' : '#FAFAFA'}
      />
      <path
        d='M55.3962 9.40513V9.98502C55.844 9.51338 56.4192 9.27755 57.1218 9.27755C57.863 9.27755 58.4304 9.5443 58.8242 10.0778C59.3183 9.5443 59.9668 9.27755 60.7698 9.27755C61.4723 9.27755 62.0436 9.49018 62.4837 9.91544C62.9238 10.333 63.1438 10.8819 63.1438 11.5623V15.3316H61.1982V11.9567C61.1982 11.6551 61.1172 11.4154 60.955 11.2376C60.7929 11.0598 60.5806 10.9708 60.3181 10.9708C60.0324 10.9708 59.7931 11.0791 59.6001 11.2956C59.4148 11.5043 59.3221 11.7866 59.3221 12.1422V15.3316H57.3766V11.9799C57.3766 11.6706 57.2955 11.427 57.1334 11.2492C56.9789 11.0636 56.7666 10.9708 56.4964 10.9708C56.203 10.9708 55.9598 11.0791 55.7668 11.2956C55.5815 11.5043 55.4889 11.7866 55.4889 12.1422V15.3316H53.5317V9.40513H55.3962Z'
        fill={isHovered ? '#2A2B3B' : '#FAFAFA'}
      />
      <path
        d='M64.876 10.1358C65.4164 9.5559 66.1036 9.26596 66.9374 9.26596C67.6554 9.26596 68.246 9.52497 68.7093 10.043V9.40513H70.5043V15.3316H68.7093V14.7053C68.2383 15.2156 67.64 15.4708 66.9142 15.4708C66.0881 15.4708 65.4049 15.1809 64.8644 14.601C64.3317 14.0133 64.0653 13.2672 64.0653 12.3626C64.0653 11.4579 64.3355 10.7157 64.876 10.1358ZM66.3583 13.3716C66.6131 13.6422 66.9374 13.7775 67.3311 13.7775C67.7172 13.7775 68.0414 13.6422 68.3039 13.3716C68.5664 13.0932 68.6977 12.753 68.6977 12.351C68.6977 11.9644 68.5664 11.6358 68.3039 11.3652C68.0414 11.0945 67.721 10.9592 67.3427 10.9592C66.949 10.9592 66.6208 11.0945 66.3583 11.3652C66.0958 11.628 65.9646 11.9605 65.9646 12.3626C65.9646 12.7724 66.0958 13.1087 66.3583 13.3716Z'
        fill={isHovered ? '#2A2B3B' : '#FAFAFA'}
      />
      <path
        d='M71.7739 15.3316V9.40513H73.7426V15.3316H71.7739ZM71.6117 7.67705C71.6117 7.35231 71.7198 7.0817 71.936 6.8652C72.1599 6.64098 72.434 6.52887 72.7582 6.52887C73.0825 6.52887 73.3527 6.64098 73.5689 6.8652C73.7928 7.0817 73.9047 7.35231 73.9047 7.67705C73.9047 7.99406 73.7966 8.26081 73.5805 8.4773C73.3566 8.70153 73.0825 8.81364 72.7582 8.81364C72.434 8.81364 72.1599 8.70539 71.936 8.4889C71.7198 8.26468 71.6117 7.99406 71.6117 7.67705Z'
        fill={isHovered ? '#2A2B3B' : '#FAFAFA'}
      />
      <path d='M77 7.20154V15.3316H75.0197V7.20154H77Z' fill={isHovered ? '#2A2B3B' : '#FAFAFA'} />
    </g>
  </svg>
)

export type BtnIconProps = SVGProps<SVGSVGElement> & { isHovered?: boolean }

export const BtnStars = ({ isHovered, ...props }: BtnIconProps) => (
  <svg
    width='24'
    height='24'
    viewBox='0 0 24 24'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    {...props}
  >
    <path
      d='M10 4C10 7.31371 7.31371 10 4 10C7.31371 10 10 12.6863 10 16C10 12.6863 12.6863 10 16 10C12.6863 10 10 7.31371 10 4Z'
      stroke={isHovered ? '#FAFAFA' : '#1C1D1F'}
      strokeWidth='2'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M17.5 15C17.5 16.3807 16.3807 17.5 15 17.5C16.3807 17.5 17.5 18.6193 17.5 20C17.5 18.6193 18.6193 17.5 20 17.5C18.6193 17.5 17.5 16.3807 17.5 15Z'
      stroke={isHovered ? '#FAFAFA' : '#1C1D1F'}
      strokeWidth='2'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </svg>
)

export const ArrowForward = ({ isHovered, ...props }: BtnIconProps) => (
  <svg
    width='25'
    height='24'
    viewBox='0 0 25 24'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    {...props}
  >
    <path
      d='M13.5 6L19.5 12M19.5 12L13.5 18M19.5 12H5.5'
      stroke={isHovered ? '#FAFAFA' : '#1C1D1F'}
      strokeWidth='2'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </svg>
)
