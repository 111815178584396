import { FC } from 'react'
import './style.scss'

export const Privacy: FC = () => {
  return (
    <div className='privacy-container'>
      <div className='privacy-text'>
        <h2>Privacy Policy</h2>
        <br />
        <h4>Effective from 25 October 2024</h4>
        <br />
        <br />
        <p>
          Dinomail Pty Ltd (“Dinomail”, ‘We”,” Us”) is committed to providing quality services to
          its users (“You”) and this policy outlines our ongoing obligations to you in respect of
          how we manage your Personal Information.
          <br />
          <br />
          We have adopted the Australian Privacy Principles (APPs) contained in the Privacy Act 1988
          (Cth) (the Privacy Act). The NPPs govern the way in which we collect, use, disclose,
          store, secure and dispose of your Personal Information. A copy of the Australian Privacy
          Principles may be obtained from the website of The Office of the Australian Information
          Commissioner at{' '}
          <a href='http://www.aoic.gov.au/' style={{ color: '#FFF' }}>
            http://www.aoic.gov.au/
          </a>
        </p>
        <br />
        <br />
        <h3>What is Personal Information and why do we collect it?</h3>
        <br />
        <p>
          Personal Information is information or an opinion that identifies an individual.
          <br />
          <br />
          Examples of Personal Information we collect include: names, addresses, email addresses,
          phone and facsimile numbers.
          <br />
          <br />
          This Personal Information is obtained in many ways including:
          <br />
          <br />
          <li>
            Via our website at{' '}
            <a href='https://magicmail.app/' style={{ color: '#FFF' }}>
              https://magicmail.app/
            </a>{' '}
            (“the Site”, “Magic Mail”)
          </li>
          <li> When you communicate with us via email or complete a survey or provide feedback</li>
          <li>
            From data provided You obtained by Your device to which you have given consent; and from
            third party services utilized by Us
          </li>
          <br />
          <br />
          We collect your Personal Information for the primary purpose of providing our services to
          You, to comply with the law and for communicating with you, including marketing
          communications. We may also use your Personal Information for secondary purposes closely
          related to the primary purpose, in circumstances where you would reasonably expect such
          use or disclosure. You may unsubscribe from our mailing/marketing lists at any time by
          contacting us in writing or using the ‘Unsubscribe’ button in any marketing communication
          but reserve the right to send you notifications related to our services and the operation
          of the Site
          <br />
          <br />
          Personal information we may collect for you includes:
          <br />
          <br />
          <li>Email address</li>
          <li>Alternate sign up or log-in methods where available</li>
          <li>Device identifier generated by the Site</li>
          <li>Your time spent on the Site</li>
          <li>Content or services accessed by you through the Site</li>
          <li>Web browser information</li>
          <li>IP address</li>
          <li>App performance data</li>
        </p>
        <br />
        <h3>How we use your data</h3>
        <br />
        <p>We will use Your data to:</p>
        <br />
        <li> Provide Our services to you</li>
        <li> Manage, maintain and operate our business and the App</li>
        <li> Suggest content or services based on you use of the App</li>
        <br />
        <p>
          We will not share or sell your data with third parties other than as specified in this
          Privacy Policy.
        </p>
        <br />
        <h3>Sensitive Information</h3>
        <br />
        <p>Sensitive information, including health information, will be used by us only:</p>
        <br />
        <li>
          For the primary purpose for which it was obtained • With your consent or where required or
          authorised by law.
        </li>
        <li>For a secondary purpose that is directly related to the primary purpose</li>
        <br />
        <p>
          The Sensitive Information we may collect from You relates to Your uses of the App and may
          include data from Apple Health Kit:
        </p>
        <br />
        <h3>Third Parties</h3>
        <br />
        <p>
          Where reasonable and practicable to do so, we will collect your Personal Information only
          from You. However, in some circumstances we may be provided with information by third
          parties. In such a case we will take reasonable steps to ensure that you are made aware of
          the information provided to us by the third party.
        </p>
        <br />
        <p>Third parties who provide us information are set out below</p>
        <br />
        <div className='table-wrapper'>
          <table style={{ width: '100%', borderCollapse: 'collapse' }}>
            <thead>
              <tr style={{ textAlign: 'left' }}>
                <th style={{ border: '1px solid #ddd', padding: '8px' }}>Third Party Service</th>
                <th style={{ border: '1px solid #ddd', padding: '8px' }}>Purpose</th>
                <th style={{ border: '1px solid #ddd', padding: '8px' }}>Privacy Policy</th>
              </tr>
            </thead>
            <tbody>
              <tr style={{ textAlign: 'left' }}>
                <td style={{ border: '1px solid #ddd', padding: '8px' }}>Firebase</td>
                <td style={{ border: '1px solid #ddd', padding: '8px' }}>
                  Analytics & communication
                </td>
                <td style={{ border: '1px solid #ddd', padding: '8px' }}>
                  https://firebase.google.com/support/privacy
                </td>
              </tr>
              <tr style={{ textAlign: 'left' }}>
                <td style={{ border: '1px solid #ddd', padding: '8px' }}>Amazon Web Services</td>
                <td style={{ border: '1px solid #ddd', padding: '8px' }}>Data storage</td>
                <td style={{ border: '1px solid #ddd', padding: '8px' }}>
                  https://aws.amazon.com/compliance/data-privacy-faq/
                </td>
              </tr>
              <tr style={{ textAlign: 'left' }}>
                <td style={{ border: '1px solid #ddd', padding: '8px' }}>Google Analytics</td>
                <td style={{ border: '1px solid #ddd', padding: '8px' }}>
                  Site performance and user behavior
                </td>
                <td style={{ border: '1px solid #ddd', padding: '8px' }}>
                  https://policies.google.com/technologies/partner-sites
                </td>
              </tr>
              <tr style={{ textAlign: 'left' }}>
                <td style={{ border: '1px solid #ddd', padding: '8px' }}>Stripe</td>
                <td style={{ border: '1px solid #ddd', padding: '8px' }}>Processing of payments</td>
                <td style={{ border: '1px solid #ddd', padding: '8px' }}>
                  https://stripe.com/au/privacy
                </td>
              </tr>
              <tr style={{ textAlign: 'left' }}>
                <td style={{ border: '1px solid #ddd', padding: '8px' }}>Xero</td>
                <td style={{ border: '1px solid #ddd', padding: '8px' }}>Financial records</td>
                <td style={{ border: '1px solid #ddd', padding: '8px' }}>
                  https://www.xero.com/au/legal/privacy/
                </td>
              </tr>
              <tr style={{ textAlign: 'left' }}>
                <td style={{ border: '1px solid #ddd', padding: '8px' }}>Google Workspace APIs</td>
                <td style={{ border: '1px solid #ddd', padding: '8px' }}>
                  Limited use for providing and improving our services. Data obtained is not used
                  for developing, improving, or training generalized AI/ML models.
                </td>
                <td style={{ border: '1px solid #ddd', padding: '8px' }}>
                  https://policies.google.com/privacy?hl=en
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <br />
        <br />
        <h3>Disclosure of Personal Information</h3>
        <br />
        <p>
          Your Personal Information may be disclosed in a number of circumstances including the
          following:
        </p>
        <br />
        <li>Third parties where you consent to the use or disclosure; and</li>
        <li>Where required or authorised by law.</li>
        <br />
        <h3>Security of Personal Information</h3>
        <br />
        <p>
          Your Personal Information is stored in a manner that reasonably protects it from misuse
          and loss and from unauthorized access, modification or disclosure. When Your Personal
          Information is no longer needed for the purpose for which it was obtained, We will take
          reasonable steps to destroy or permanently de-identify your Personal Information. However,
          most of the Personal Information is or will be stored by us for a minimum of 7 years. All
          Personal Data is secured at rest using industry standard encryption and data is
          transmitted over a secure connection.
        </p>
        <br />
        <h3>Access to your Personal Information</h3>
        <br />
        <p>
          You may access the Personal Information We hold about you and to update and/or correct it,
          subject to certain exceptions. If you wish to access your Personal Information, please
          contact us in writing. We will not charge any fee for your access request but may charge
          an administrative fee for providing a copy of your Personal Information. In order to
          protect your Personal Information, We may require identification and other evidence from
          You before releasing the requested information.
        </p>
        <br />
        <h3>Maintaining the Quality of your Personal Information</h3>
        <br />
        <p>
          It is important to us that Your Personal Information is up to date. We will take
          reasonable steps to make sure that Your Personal Information is accurate, complete and
          up-to-date. If You find that the information we have is not up to date or is inaccurate,
          please advise Us as soon as practicable so we can update our records and ensure we can
          continue to provide quality services to you.
        </p>
        <br />
        <h3>Note to EU citizens and residents</h3>
        <br />
        <p>
          Your data may be stored in locations outside of the EU, including in countries or
          jurisdictions, such as Australia or the US, where privacy protections are different to
          those in the EU. By using the App, you consent for any personal information to be stored
          in these locations.
        </p>
        <br />
        <p>
          <strong>What are your data protection rights?</strong>
        </p>
        <br />
        <p>
          We would like to make sure you are fully aware of all of your data protection rights.
          Every user is entitled to the following:
        </p>
        <br />
        <p>
          <strong>The right to access </strong> – You have the right to request Our Company for
          copies of your personal data. We may charge you a small fee for this service.
          <br />
          <br />
          <strong>The right to rectification</strong> – You have the right to request that We
          correct any information You believe is inaccurate. You also have the right to request Us
          to complete the information You believe is incomplete.
          <br />
          <br />
          <strong>The right to erasure</strong> – You have the right to request that We erase your
          personal data, under certain conditions.
          <br />
          <br />
          <strong>The right to restrict processing</strong> – You have the right to request that We
          restrict the processing of your personal data, under certain conditions.
          <br />
          <br />
          <strong>The right to object to processing</strong> – You have the right to object to Our
          processing of Your personal data, under certain conditions.
          <br />
          <br />
          <strong>The right to data portability</strong> – You have the right to request that We
          transfer the data that we have collected to another organization, or directly to you,
          under certain conditions.
        </p>
        <br />
        <p>
          If you make a request, we have one month to respond to you. If you would like to exercise
          any of these rights, please contact us via the methods set out below
        </p>
        <br />
        <p>
          If you make a request, we have one month to respond to you. If you would like to exercise
          any of these rights, please contact us via the methods set out below
        </p>
        <br />
        <h3>Policy Updates</h3>
        <br />
        <p>
          This Policy may change from time to time and is available on our website and via the
          Dinomail App.
        </p>
        <br />
        <h3>Data Control</h3>
        <br />
        <p>All data collected is controlled by Dinomail Pty Ltd and related business entities.</p>
        <br />
        <h3>Privacy Policy Complaints and Enquiries</h3>
        <br />
        <p>If you have any queries or complaints about our Privacy Policy please contact us at:</p>
        <br />
        <p>The Data Controller</p>
        <p>
          Dinomail Pty Ltd
          <br /> 54 Banrock Ave
          <br />
          Ellenbrook WA 6069
          <br /> Australia
        </p>
        <p>Via email 3ddmitriy@gmail.com</p>
      </div>
    </div>
  )
}
